// ui

export const SET_BACKGROUND = 'SET_BACKGROUND'
export const UPDATE_BACKGROUND = 'UPDATE_BACKGROUND'
export const SET_LANGUAGE = 'SET_LANGUAGE'
export const SET_ALERT_VISIBILITY = 'SET_ALERT_VISIBILITY'
export const SET_ALERT_TYPE = 'SET_ALERT_TYPE'
export const SET_PLAY_STATUS = 'SET_PLAY_STATUS'
export const SET_PLAYER_VISIBILITY = 'SET_PLAYER_VISIBILITY'
// data
export const SET_CURRENT_TOPIC_DATA = 'SET_CURRENT_TOPIC_DATA'
export const SET_STORIES_DATA = 'SET_STORIES_DATA'

// all tioics
export const SET_TOPIC_DATA = 'SET_TOPIC_DATA'
export const SET_TOPIC_INDEX = 'SET_TOPIC_INDEX'
export const SET_HORIZONTAL_INDEX = 'SET_HORIZONTAL_INDEX'

// muted
export const SET_MUTED = 'SET_MUTED'