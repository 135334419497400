export const translate = (word, lang) => {    
 
    const wordsObject = {
        Home: { gb: 'Home', ru: 'Главная', de: 'Startseite', it: 'Homepage', es: 'Página de inicio' },
        Company: { gb: 'Company', ru: 'Компания', de: 'Unternehmen', it: 'Azienda', es: 'Empresa' },
        Contacts: { gb: 'Contacts', ru: 'Контакты', de: 'Kontakte', it: 'Contatti', es: 'Contactos' },
        Downloads: { gb: 'Downloads', ru: 'Загрузка', de: 'Herunterladen', it: 'Caricamento in corso', es: 'Cargando' },
        Legal: { gb: 'Legal', ru: 'Юридические аспекты', de: 'Legale Aspekte', it: 'Aspetti legali', es: 'Aspectos legales' },
        Terms_Of_Use: { gb: 'Terms Of Use', ru: 'Условия использования', de: 'Nutzungsbedingungen', it: 'Termini di utilizzo', es: 'Condiciones de uso' },
        Privacy_Policy: { gb: 'Privacy Policy', ru: 'Политика конфиденциальности', de: 'Datenschutz-Bestimmungen', it: 'Politica sulla riservatezza', es: 'Política de privacidad' },
        Eula: { gb: 'End-User License Agreement', ru: 'Пользовательское соглашение', de: 'Nutzungsbedingungen', it: 'Termini di utilizzo', es: 'Condiciones de uso' },
        Discuss_argue_win: { gb: 'Discuss, argue, win', ru: 'Обсуждение, аргументы, победа', de: 'Diskussion, Argumente, Sieg', it: 'Discussione, discussioni, vittoria', es: 'Discusión, argumentos, victoria' },
        Download_now: { gb: 'Download now', ru: 'Скачать сейчас', de: 'Jetzt downloaden', it: 'Scarica ora', es: 'Descargar ahora' },
        Our: { gb: 'Our', ru: 'Наши', de: 'Unsere', it: 'Nostro', es: 'Nuestra' },
        and: { gb: 'and', ru: 'и', de: 'und', it: 'e', es: 'y' },
        Scrool_down: { 
            gb: `Click for the\n next video`, 
            ru: `Потяните вниз для просмотра следующего видео`, 
            de: `Ziehen Sie nach unten, um das nächste Video anzusehen`, 
            it: `Tira giù per guardare il prossimo video`, 
            es: `Tira hacia abajo para ver el siguiente video`
        },
        we_solve_conflicts: { 
            gb: 'we solve conflicts', 
            ru: 'мы решаем конфликты', 
            de: 'wir lösen konflikte', 
            it: 'risolviamo i conflitti', 
            es: 'Nosotros resolvemos conflictos'
        }
    }
    
    var ln = lang.toLowerCase()

    try {
        return wordsObject[word][ln]
    } catch (e) {
        return word
    }
}
