import React, { useEffect, useState } from "react"
import ReactPlayer from 'react-player'
import {isMobile } from 'react-device-detect'
import { setPlayStatus } from "../redux/action/uiAction"
import { useDispatch, useSelector } from 'react-redux'
import { setMuted } from "../redux/action/dataAction"

const VideoPlayer = ({player, topicData, rowIndex, index, updatePlaybackData, setDuration, playStatus, loop, playButtonVisibility}) => {

    const [ready, setReady] = useState(false)

    // console.log("VideoPlayer", topicData[0]?.answers[rowIndex]?.video?.covers?.basic)

    const dispatch = useDispatch()

    const muted = useSelector(state => state.data.muted)

    function readyHandler() {
        // console.log("onReady")
        playButtonVisibility()
        setReady(!ready)
        if(isMobile) {
            if(index !==0) {
                dispatch(setPlayStatus(true))
                // dispatch(setMuted(false))
            } else {
                dispatch(setMuted(false))
            }
        }
    }
    useEffect(() => {
        setReady(false)
    }, [rowIndex, index])
    return (
        <ReactPlayer 
            ref={player}
            // url={`videos/${topicData && topicData[index] && topicData[index].answers[rowIndex].video.mock}`}
            url={topicData[index]?.answers[rowIndex]?.video?.url}
            config={{
                file: { 
                    attributes: {
                        // poster: `videos/${topicData && topicData[index] && topicData[index].answers[rowIndex].video.covers.mock}`,
                        poster: topicData[index]?.answers[rowIndex]?.video?.covers?.basic
                    } 
                },
            }}
            onProgress={played => updatePlaybackData(played.playedSeconds)}
            onDuration={duration => setDuration(duration)}
            width={!isMobile ? 338 : "100vw"}
            height={!isMobile ? 680 : "100vh"}
            playing={playStatus}
            loop={loop} 
            progressInterval={50}
            playsinline={true}
            muted={muted}
            volume={1}
            onReady={readyHandler}
            // onBuffer={() => console.log("onBuffer")}
            // onBufferEnd={() => console.log("onBufferEnd")}
        />
    )
}

export default VideoPlayer
