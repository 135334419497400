import { createStore, combineReducers, applyMiddleware, compose } from "redux"
import thunk from 'redux-thunk'
import ui from '../redux/reducer/ui'
import data from '../redux/reducer/data'

const initialState = {}

const middleware = [thunk]

const reducers = combineReducers({
    UI: ui,
    data: data
})

// const devTools = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__() : null

const store = createStore(   
    reducers,   
    initialState,    
    compose (
       applyMiddleware(...middleware),
       //devTools       
    )    
)

export default store
