import { SET_CURRENT_TOPIC_DATA, SET_STORIES_DATA, SET_TOPIC_DATA, SET_TOPIC_INDEX, SET_HORIZONTAL_INDEX,
    SET_MUTED } from '../type'

const initialState = {
    currentTopicData: null,
    storiesData: [],
    topicData: [],
    index: 0,
    rowIndex: 0,
    muted: true
}

const data = (state = initialState, action) => {
    switch (action.type) {
        case SET_CURRENT_TOPIC_DATA:
           return {
               ...state,
               currentTopicData: action.payload
           }
        case SET_STORIES_DATA: {
            return {
                ...state,
                storiesData: action.payload
            }
        }  
        case SET_TOPIC_DATA: {
            return {
                ...state,
                topicData: action.payload
            }
        }  
        case SET_TOPIC_INDEX: {
            return {
                ...state,
                index: action.payload
            }
        }   
        case SET_HORIZONTAL_INDEX: {
            return {
                ...state,
                rowIndex: action.payload
            }
        }   
        case SET_MUTED: {
            return {
                ...state,
                muted: action.payload
            }
        } 
        default: return state
    }
}

export default data