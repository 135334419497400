import {SET_ALERT_TYPE, SET_ALERT_VISIBILITY, SET_BACKGROUND, SET_LANGUAGE, SET_PLAY_STATUS, UPDATE_BACKGROUND, SET_PLAYER_VISIBILITY} from '../type'

const initialState = {
    background: [],
    language: "gb",
    alert: false,
    alertType: 0, // app installed
    playStatus: false,
    playerVisibility: true
}

const ui = (state = initialState, action) => {
    switch (action.type) {
        case SET_BACKGROUND:
           return {
               ...state,
               background: action.payload
           }

        case UPDATE_BACKGROUND: {
            
            let index = action.payload.index
            let type = action.payload.type
            let url = action.payload.url

            state.background[index] = {type, url}

            return {
                ...state,
                background: [...state.background]
            }
        }

        case SET_LANGUAGE: {
            return {
                ...state,
                language: action.payload
            }
        }   
        case SET_ALERT_VISIBILITY: {
            return {
                ...state,
                alert: action.payload
            }
        }
        case SET_ALERT_TYPE: {
            return {
                ...state,
                alertType: action.payload
            }
        }
        case SET_PLAY_STATUS: {
            return {
                ...state,
                playStatus: action.payload
            }
        }
        case SET_PLAYER_VISIBILITY: {
            return {
                ...state,
                playerVisibility: action.payload
            }
        }
        default: return state
    }
}

export default ui