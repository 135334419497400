import React from "react"
import { useSelector, useDispatch } from 'react-redux'
import { isIOS } from 'react-device-detect'
import Alert from 'react-popup-alert'

import logoW from '../img/logo.svg'
import close from '../img/close-white.png'
import judge from '../img/judge.jpg' 
import { setAlert } from "../redux/action/uiAction"

const AlertComponent = ({closeAlertWindow, app}) => {

    const dispatch = useDispatch()   

    const alertType = useSelector(state => state.UI.alertType)

     // alert text
     const alertData = {
        0: {
            "header": "App gives you full functionality",
            "btnText": "Open Disbate",
            "alertText": "Create debates, disputes, make friends, comment, rate and much more"
        },
        1: {
            "header": "Download App to get full access",
            "btnText": "Download",
            "alertText": "Discover best debates and disputes, create your own, comment, rate and much more!"
        }
    }

    function onPressAlert() {
        var now = new Date().valueOf();
        setTimeout(function () {
            if (new Date().valueOf() - now > 100) return;
            if(isIOS) {
               var url = "https://apps.apple.com/ru/app/moon-current-moon-phase/id660036257";
            } else {
                url = "https://play.google.com/store/apps/details?id=com.xd.tothemoon&hl=ru&gl=US"
            }
            window.location.replace = url
            dispatch(setAlert(false))
        }, 50);
        window.location = "disbate://"
        dispatch(setAlert(false))
    }


    return  (
        <div style={{
            height: window.innerHeight*0.6,
            width: window.innerWidth*0.9,
            position: "absolute",
            left: "5%",
            top: "20%", 
            zIndex: 1000,
            background: "#396BCC",
            borderRadius: 8,
            display: "flex",
            flexDirection: "column",
            alignItems:  "center",
            paddingTop: 30,
            borderWidth: 0,
            backgroundImage: `url(${judge})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            //filter: "blur(18px)"
        }}>
            <img src={logoW} alt="disbate" className="logoIcon" style={{marginTop: window.innerHeight*0.11}}/>
            <button className="btn closeContainer" onClick={closeAlertWindow}>
                <img src={close} alt="disbate" className="closeIcon"/>
            </button>
            {/* <span>{app}</span> */}
        <Alert
            header={alertData[alertType].header}
            btnText={alertData[alertType].btnText}
            text={alertData[alertType].alertText}
            show={alert}
            onClosePress={onPressAlert}
            pressCloseOnOutsideClick={true}
            showBorderBottom={true}
            alertStyles={{
                position: "absolute",
                left: 0,
                top: window.innerHeight*0.35, 
                zIndex: 1000,
                background: "#fff",
                borderBottomLeftRadius: 8,
                borderBottomRightRadius: 8,
                display: "flex",
                flexDirection: "column",
                alignItems:  "center",
                paddingTop: 30,
                border: "none"
            }}
            headerStyles={{
                fontSize: 20,
                lineHeigth: 22,
                fontWeigth: 700,
                color: "#000",
                textAlign: "center"
            }}
            textStyles={{
                fontSize: 17,
                lineHeigth: 20,
                fontWeigth: 700,
                color: "#000",
                textAlign: "center",
                padding: 5
            }}
            buttonStyles={{
                width: "90%",
                height: 40,
                borderRadius: 8,
                background: "rgb(220, 53, 69)",
                fontSize: 20,
                lineHeigth: 24,
                fontWeight: 700,
                color: "#fff",
                textAlign: "center",
                paddingTop: 3,
                paddingBottom: 2,
                textDecoration: "none",
                marginBottom: 30

            }}
        />
    </div>
    )
}

export default AlertComponent

const backgroundStyle = {
    position: "absolute",
    height: "100vh",
    width: "100vw",
    // backgroundColor: "red"
} 
