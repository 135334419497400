import {SET_ALERT_TYPE, SET_ALERT_VISIBILITY, SET_BACKGROUND, SET_LANGUAGE, SET_PLAY_STATUS, UPDATE_BACKGROUND, SET_PLAYER_VISIBILITY} from '../type'

// dynamic backgrounf
export const setBackground = data => {
    return {
        type: SET_BACKGROUND,
        payload: data
    }
}

export const updateBackground = data => {
    return {
        type: UPDATE_BACKGROUND,
        payload: {
            index: data.index, 
            type: data.type, 
            url: data.url
        }
    }
}

// app language
export const setLanguage = data => {
    return {
        type: SET_LANGUAGE,
        payload: data
    }
}

// alert visibility
export const setAlert = data => {
    return {
        type: SET_ALERT_VISIBILITY,
        payload: data
    }
}

// alert type 0 - app not installed; 1 = app installed
export const setAlertType = data => {
    return {
        type: SET_ALERT_TYPE,
        payload: data
    }
}

// play status
export const setPlayStatus = data => {
    return {
        type: SET_PLAY_STATUS,
        payload: data
    }
}


export const setPlayerVisibility = data => {
    return {
        type: SET_PLAYER_VISIBILITY,
        payload: data
    }
}
