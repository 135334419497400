import { SET_CURRENT_TOPIC_DATA, SET_STORIES_DATA, SET_TOPIC_DATA, SET_TOPIC_INDEX, SET_HORIZONTAL_INDEX, SET_MUTED } from '../type'

export const setCurrentTopicData = data => {
    return {
        type: SET_CURRENT_TOPIC_DATA,
        payload: data
    }
}

export const setStoriesData = data => {
    return {
        type: SET_STORIES_DATA,
        payload: data
    }
}

export const setTopicData = data => {
    return {
        type: SET_TOPIC_DATA,
        payload: data
    }
}

export const setIndex = data => {
    return {
        type: SET_TOPIC_INDEX,
        payload: data
    }
}

export const setRowIndex = data => {
    return {
        type: SET_HORIZONTAL_INDEX,
        payload: data
    }
}

export const setMuted = data => {
    return {
        type: SET_MUTED,
        payload: data
    }
}
